import mapDisabledImage from '../img/map-disabled.webp'
import mapDisabledImageMobile from '../img/mobile-map-disabled.webp'
import mapEnabledImage from '../img/map-enabled.webp'
import mapIconsDisabledImage from '../img/disabled-icons.webp'
import mapIconsEnabledImage from '../img/enabled-icons.webp'
import mapEnabledImageMobile from '../img/mobile-map-enabled.webp'
import mapIconsDisabledImageMobile from '../img/mobile-disabled-icons.webp'
import mapIconsEnabledImageMobile from '../img/mobile-enabled-icons.webp'
import knightBodyImage from '../img/knight-body.png'
import clickHereImage from '../img/click-here.webp'
import html2canvas from 'html2canvas'
import rollScene from "./rolls"

//************assign values to query selectors on first load of page
let gameInfo = document.querySelector(".game-info")
let playerScore = gameInfo.querySelector(".score")
let playerLevel = gameInfo.querySelector(".profile-level")

let profile = document.querySelector(".profile")
let name = profile.querySelector(".user-name")
let profilePoints = profile.querySelector(".your-points")
let profileLevel = profile.querySelector(".profile-level-num")

window.arrowVisible = true
window.arrowGroup = document.querySelector('#desktop-map .click-here-img')


//update points which can only be done once in the beginning
playerScore.innerHTML = window.points

//set name, points, and current level inside profile info
//update name and points which can only be done once in the beginning.
name.innerHTML = window.name
profilePoints.innerHTML = window.points
const motionPathPositions = [0, 0.28, 0.522, 0.746, 1]
const mobileMotionPathPositions = [0, 0.2, 0.452, 0.7, 1]

let state = {
  knightPosition: {}
}

// Set the knight's initial position to 0
function setKnightPosition(level) {
  for (const displayMap of document.querySelectorAll('#desktop-map, #mobile-map')) {
    var displayLevel = Math.min(level, 4)

    var knight = displayMap.querySelector('.knight-icon')
    knight.style.transform = getKnightPosition(displayMap, displayLevel)


    //checks to set arrow to visible in mobile and desktop
    if (level == 0 && window.innerWidth > 600) {
      window.mobile = false
      getKnightPosition(document.querySelector("#desktop-map"), 0)
      window.arrowGroup = document.querySelector('#desktop-map .click-here-img')
    }

    if (level == 0 && window.innerWidth <= 600) {
      window.mobile = true
      getKnightPosition(document.querySelector("#mobile-map"), 0)
      window.arrowGroup = document.querySelector('#mobile-map .click-here-img')
    }

    for (const element of displayMap.querySelectorAll('.map-enabled')) {
      if (parseInt(element.dataset.level) == level+1) {
        element.classList.add('current')
        element.style.opacity = 1
      }
    }
  }

  //set the arrow
  if (level == 0 && window.arrowVisible) {
    setArrowPosition()
  }
}

window.setArrowPosition = function() {
  let arrow;

  if (window.mobile) {
    arrow = document.querySelector('#mobile-map .click-here')
    getKnightPosition(document.querySelector("#mobile-map"), 0)
  }
  else {
    arrow = document.querySelector('#desktop-map .click-here')
    getKnightPosition(document.querySelector("#desktop-map"), 0)
  }

  let arrowPosition = arrow.getBBox()

  state.knightPosition.x += (-26)
  state.knightPosition.y += (-arrowPosition.height)

  let arrowTransform = 'translate(' + state.knightPosition.x + "px, " + state.knightPosition.y + "px)"
  arrow.style.transform = arrowTransform

}

window.addEventListener('resize', function(){
  setKnightPosition(window.lastLevelBeaten)
})

HTMLCanvasElement.prototype.getContext = function(origFn) {
  return function(type, attribs) {
    attribs = attribs || {};
    attribs.preserveDrawingBuffer = true;
    return origFn.call(this, type, attribs);
  };
}(HTMLCanvasElement.prototype.getContext)

const ignoreTypes = ['path', 'clippath', 'svg', 'iframe', 'script']
function canvasIgnoreElements(el) {
  if (el.className == 'map-container') return true
  if (ignoreTypes.includes(el.nodeName.toLowerCase())) return true
  if (el.nodeName.toLowerCase() == 'img') {
    if (el.src.includes('knight')) return true
    if (el.src.includes('new-item')) return true
    if (el.src.includes('paper-texture')) return true
  }
  if (el.className.includes('video')) return true
  if (el.className.includes('ending')) return true
  if (el.className.includes('menu-container')) return true

  if (typeof el.shadowRoot == 'object' && el.shadowRoot !== null) return true

  return false
}

// Listen for clicks on the outside of modals to close them
for (const elem of document.querySelectorAll('.scroll-container')) {
  elem.addEventListener('click', function(e){
    if (e.target.classList.contains('scroll-container')) {
      e.target.querySelector('.close-help-button, .close-level-button, .close-profile-button').click()
    }
  })
}


const endingBox = document.querySelector('.ending-background')
endingBox.addEventListener('click', function(e){
  if (!e.target.classList.contains('ending-background')) return
  endingBox.parentNode.querySelector('.ending-screen-close-button').click()
})

const videoContainer = document.querySelector('.video-container')
videoContainer.addEventListener('click', function(e){
  if (!e.target.classList.contains('video-container')) return
  videoContainer.querySelector('.video-back-to-map').click()
})

window.startScroll = function(div, duration, angle){
  // Clean up old screenshots
  if (document.getElementById('screenshot-canvas') != null) document.getElementById('screenshot-canvas').remove()
  if (document.querySelector('#container canvas') != null) document.querySelector('#container canvas').remove()

  // Skip the unroll on smaller screens or if the user prefers reduced motion
  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches || (window.innerWidth < 512 || window.navigator.userAgent.includes('iPhone') || window.navigator.userAgent.includes('Android'))) {
    if (div == document.getElementById('how-to')) {
      gsap.to('#loading-cover', { autoAlpha: 0, duration: 2 }, 0)
    }

    gsap.fromTo(div, {opacity: 0 }, { opacity: 1 })
    div.parentElement.classList.add('clickable')
    div.parentElement.classList.add('visible')
    div.parentElement.classList.add('active')
    return
  }

  div.parentNode.style.visibility = 'none'
  div.style.visibility = 'none'
  div.style.opacity = 1
  div.parentNode.classList.add("active")
  div.parentNode.classList.remove("clickable")
  div.parentNode.classList.remove("visible")
  html2canvas(div, { backgroundColor: null, ignoreElements: canvasIgnoreElements }).then(function(canvas) {
      canvas.setAttribute('id', 'screenshot-canvas')
      div.style.opacity = 0
      div.parentElement.classList.add('visible')
      canvas.toBlob(function(blob){
        var scene = new rollScene("container")
        var mesh = scene.createMesh({
          width: canvas.width / window.devicePixelRatio,
          height: canvas.height / window.devicePixelRatio,
          image: document.getElementById('paper-texture'),
          iWidth: canvas.width / window.devicePixelRatio,
          iHeight: canvas.height / window.devicePixelRatio,
          frontImage: canvas
        });
        scene.scene.add(mesh)

        scene.play()
        scene.render()
        var renderer = function(){
          if (scene == null) return;
          scene.render()
          window.requestAnimationFrame(renderer)
        }
        window.requestAnimationFrame(renderer)
        mesh.children[0].material.uniforms.progress.value = 0;
        mesh.children[0].material.uniforms.angle.value = angle;
        mesh.children[1].material.uniforms.angle.value = angle;
        var unrollTimeline = gsap.timeline()
        unrollTimeline.to('#loading-cover', { autoAlpha: 0, duration: 2 }, 0)
        unrollTimeline.to(mesh.children[0].material.uniforms.progress, {
          duration: duration,
          value: 1,
          ease: "power2.out",
          onComplete: () => {
            scene.renderer.domElement.remove()
            if (!div.parentNode.classList.contains('active')) return
            scene = null
            div.parentNode.classList.add('clickable')
            div.style.opacity = 1
          }
        }, 0)
        unrollTimeline.to(mesh.children[1].material.uniforms.progress, {
          duration: duration,
          value: 1,
          ease: "power2.out",
          onComplete: () => {
          }
        }, 0)
      })
  });
}

var paperImage = document.querySelector('#paper-texture')


//if lastLevelBeaten = 0
window.onload = function() {
  if (window.lastLevelBeaten == 0) {
    window.startScroll(document.getElementById('how-to'), 5, 0.2)
  }
}

if (window.lastLevelBeaten > 0) {
  gsap.to('#loading-cover', { autoAlpha: 0, duration: 2 }, 0)
}



function getKnightPosition(displayMap, level) {
  var knight = displayMap.querySelector('.knight-icon')
  var currentIcon = displayMap.querySelectorAll('.icon-masks')[level].querySelector('circle').getBBox()

  if (level == 0) {
    state.knightPosition.x = (currentIcon.x - 32 + currentIcon.width/2)
    state.knightPosition.y = (currentIcon.y - 85 + currentIcon.height/2)
  }


  return 'translate(' + (currentIcon.x - 32 + currentIcon.width/2) + 'px, ' + (currentIcon.y - 85 + currentIcon.height/2) + 'px)'
}


setKnightPosition(0)

window.moveToNextIsland = function(){
  var lastIndex = window.lastLevelBeaten
  window.lastLevelBeaten++
  makePreviousIslandsActive()

  gsap.to('#desktop-map .knight-icon', {
    duration: 5,
    motionPath: {
      path: '#desktop-map #motion-path',
      offsetY: -85,
      offsetX: -32,
      start: motionPathPositions[lastIndex],
      end: motionPathPositions[window.lastLevelBeaten] }
    }
  )

  gsap.to('#mobile-map .knight-icon', {
    duration: 3,
    motionPath: {
      path: '#mobile-map #motion-path',
      offsetY: -85,
      offsetX: -32,
      start: mobileMotionPathPositions[lastIndex],
      end: mobileMotionPathPositions[window.lastLevelBeaten] }
    }
  )
}

function debounce(func, wait, immediate) {
	var timeout
	return function() {
		var context = this, args = arguments
		var later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		var callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

/*
 * Loading images, for Parcel's require stuff to work
 */
document.querySelector('#desktop-map #disabled-map').setAttribute('href', mapDisabledImage)
document.querySelector('#mobile-map #disabled-map').setAttribute('href', mapDisabledImageMobile)

const loadImages = function(){
  for (const image of document.querySelectorAll('.map .knight-icon')) {
    image.setAttribute('href', knightBodyImage)
  }
  for (const image of document.querySelectorAll('.map .click-here-img')) {
    image.setAttribute('href', clickHereImage)
  }

  if (window.innerWidth <= 600) {
    for (const image of document.querySelectorAll('#mobile-map .map-enabled')) {
      image.setAttribute('href', mapEnabledImageMobile)
    }
    for (const image of document.querySelectorAll('#mobile-map .map-icons-disabled')) {
      image.setAttribute('href', mapIconsDisabledImageMobile)
    }
    for (const image of document.querySelectorAll('#mobile-map .map-icons-enabled')) {
      image.setAttribute('href', mapIconsEnabledImageMobile)
    }
  } else {
    for (const image of document.querySelectorAll('#desktop-map .map-enabled')) {
      image.setAttribute('href', mapEnabledImage)
    }
    for (const image of document.querySelectorAll('#desktop-map .map-icons-disabled')) {
      image.setAttribute('href', mapIconsDisabledImage)
    }
    for (const image of document.querySelectorAll('#desktop-map .map-icons-enabled')) {
      image.setAttribute('href', mapIconsEnabledImage)
    }
  }
}
loadImages()
window.addEventListener('resize', debounce(loadImages, 100))


let makePreviousIslandsActive = () => {

  for (const displayMap of document.querySelectorAll('#desktop-map, #mobile-map')) {

    // Set the icons to gold
    for (const element of displayMap.querySelectorAll('.map-icons-enabled')) {
      if (parseInt(element.dataset.level) <= window.lastLevelBeaten) {
        element.style.opacity = 1
        element.classList.add('current')
      }
    }

    // Hide the disabled icon
    for (const element of displayMap.querySelectorAll('.map-icons-disabled')) {
      if (parseInt(element.dataset.level) <= window.lastLevelBeaten)
        element.style.opacity = 0
    }

    // The island itself
    for (const element of displayMap.querySelectorAll('.map-enabled')) {
      if (parseInt(element.dataset.level) <= window.lastLevelBeaten)
        element.style.opacity = 1

      if (parseInt(element.dataset.level) == window.lastLevelBeaten + 1) {
        element.classList.add('current')
        element.style.opacity = 1
      }
    }
  }

  setKnightPosition(window.lastLevelBeaten)
}

//update level info which could change throughout the game
profileLevel.innerHTML = window.lastLevelBeaten + 1
playerLevel.innerHTML = window.lastLevelBeaten + 1

for (const element of document.querySelectorAll('.map-icons-enabled')) {
  element.style.opacity = 0
}

//move knight to where it should be
if (window.lastLevelBeaten > 0) {
  makePreviousIslandsActive()
  window.arrowVisible = false
}
