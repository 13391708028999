/*
 * From the wonderful https://github.com/akella/UnrollingImages
 */
import { Scene } from 'three/src/scenes/scene';
import { Group } from 'three/src/objects/group';
import { WebGLRenderer } from 'three/src/renderers/webglrenderer';
import { PerspectiveCamera } from 'three/src/cameras/perspectivecamera';
import { PlaneBufferGeometry } from 'three/src/geometries/planegeometry';
import { ShaderMaterial } from 'three/src/materials/shadermaterial';
import { Texture } from 'three/src/textures/texture';
import { Mesh } from 'three/src/objects/mesh';
import { sRGBEncoding, FrontSide, BackSide, LinearFilter } from 'three/src/constants';
import { Vector4 } from 'three/src/math/vector4'
import { Vector2 } from 'three/src/math/vector2'
import fragment from "./shader/fragment.glsl";
import vertex from "./shader/vertex.glsl";

export default class Sketch {
  constructor(selector) {
    this.scene = new Scene();

    this.renderer = new WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.sortObjects = false;

    this.renderer.outputEncoding = sRGBEncoding;

    this.container = document.getElementById("container");
    this.container.appendChild(this.renderer.domElement);

    this.camera = new PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      300,
      1000
    );

    this.cameraDistance = 400;
    this.camera.position.set(0, 0, this.cameraDistance);
    this.camera.lookAt(0, 0, 0);
    this.time = 0;

    this.paused = false;

    this.settings();
    this.setupResize();

    this.addObjects();
    this.resize();
    this.render();
  }

  settings() {
    let that = this;
    this.settings = {
      progress: 0,
      angle: 0.2
    };
    // this.gui = new dat.GUI();
    // // this.gui.add(this.settings, "progress", -1, 2, 0.01);
    // this.gui.add(this.settings, "progress", 0, 1, 0.01);
    // this.gui.add(this.settings, "angle", 0, 3.1415, 0.01);
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;

    this.camera.fov =
      2 *
      Math.atan(this.width / this.camera.aspect / (2 * this.cameraDistance)) *
      (180 / Math.PI); // in degrees

    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    let that = this;

    this.geometry = new PlaneBufferGeometry(1, 1, 120, 80)
    this.backGeometry = new PlaneBufferGeometry(1, 1, 120, 80)
    //this.backGeometry.applyMatrix4( new Matrix4().makeRotationY( Math.PI ) )

    this.material = new ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: FrontSide,
      uniforms: {
        time: { type: "f", value: 0 },
        progress: { type: "f", value: 0 },
        angle: { type: "f", value: 0 },
        texture1: { type: "t", value: null },
        texture2: { type: "t", value: null },
        resolution: { type: "v4", value: new Vector4() },
        uvRate1: {
          value: new Vector2(1, 1)
        },
        backface: { type: "f", value: 0 },
      },
      //wireframe: true,
      depthTest: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });
  }

  createMesh(o, backface) {
    let material = this.material.clone();
    let texture = new Texture(o.image);
    texture.needsUpdate = true;
    let texture2 = new Texture(o.frontImage);
    texture2.needsUpdate = true;
    // image cover
    let imageAspect = o.iHeight / o.iWidth;
    let a1;
    let a2;
    if (o.height / o.width > imageAspect) {
      a1 = (o.width / o.height) * imageAspect;
      a2 = 1;
    } else {
      a1 = 1;
      a2 = o.height / o.width / imageAspect;
    }
    texture.minFilter = LinearFilter;
    texture2.minFilter = LinearFilter;
    material.uniforms.resolution.value.x = o.width;
    material.uniforms.resolution.value.y = o.height;
    material.uniforms.resolution.value.z = a1;
    material.uniforms.resolution.value.w = a2;
    material.uniforms.progress.value = 0;
    material.uniforms.angle.value = 0.3;
    material.uniforms.backface.value = 0.0;

    material.uniforms.texture1.value = texture2;
    material.uniforms.texture1.value.needsUpdate = true;

    let mesh = new Mesh(this.geometry, material)
    mesh.scale.set(o.width, o.height, o.width / 2)

    let backMaterial = new ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: FrontSide,
      uniforms: {
        time: { type: "f", value: 0 },
        progress: { type: "f", value: 0 },
        angle: { type: "f", value: 0 },
        texture1: { type: "t", value: null },
        texture2: { type: "t", value: null },
        resolution: { type: "v4", value: new Vector4() },
        uvRate1: {
          value: new Vector2(1, 1)
        },
        backface: { type: "f", value: 1.0 },
      },
      //wireframe: true,
      depthTest: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });
    backMaterial.uniforms.backface.value = 1.0
    backMaterial.side = BackSide
    backMaterial.uniforms.texture1.value = texture
    backMaterial.uniforms.resolution.value.x = o.width;
    backMaterial.uniforms.resolution.value.y = o.height;
    backMaterial.uniforms.resolution.value.z = a1;
    backMaterial.uniforms.resolution.value.w = a2;
    backMaterial.uniforms.progress.value = 0;
    backMaterial.uniforms.angle.value = 0.3;
    backMaterial.uniforms.backface.value = 0.0;
    backMaterial.uniforms.texture1.value.needsUpdate = true
    let meshBack = new Mesh(this.backGeometry, backMaterial)
    meshBack.scale.set(o.width, o.height, o.width / 2)
    meshBack.position.z = 0.1;

    let group = new Group()
    group.add(mesh)
    group.add(meshBack)

    this.group = group

    return group;
  }

  stop() {
    this.paused = true;
  }

  play() {
    this.paused = false;
    this.render();
  }

  render() {
    if (typeof this.group == 'undefined') return
    /*this.group.children.forEach(m => {
      if (m.material.uniforms) {
        m.material.uniforms.angle.value = this.settings.angle;
      }
    });*/
    this.renderer.render(this.scene, this.camera);
  }
}
